import { ReportEntity } from './reports'
import axios from 'axios'
const CIRKULA_STATUS_URL = 'https://api.cirkulaapp.com'
const CIRKULA_URL = 'https://api.maagalapp.com'

type ServiceResponse = {
  status: boolean | string
}

const checkDatabaseConnection = async () => {
  const response = await axios.get(`${CIRKULA_STATUS_URL}/sql`, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data
}

const checkMailServer = async () => {
  const response = await axios.get(`${CIRKULA_STATUS_URL}/mail`, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data
}

const checkServerStatus = async () => {
  const response = await axios.get(`${CIRKULA_URL}/status`, {
    headers: { 'Content-Type': 'application/json' },
  })
  return { status: response.status === 200 }
}

class Services implements ReportEntity {
  request: () => Promise<ServiceResponse>

  constructor(rqst: () => Promise<ServiceResponse>) {
    this.request = rqst
  }
  get = async () => {
    try {
      var res = await this.request()
      return res.status === true
    } catch (error) {
      return false
    }
  }
}

const SQLConnection = new Services(checkDatabaseConnection)
const ServerConnection = new Services(checkServerStatus)
const MailConnection = new Services(checkMailServer)

export { SQLConnection, ServerConnection, MailConnection }
