import { LIGHT_RED, SECONDARY_COLOR } from "@utils/colors";
import { FirebaseDate } from "@utils/firebase";
import { StyleProps } from "@utils/interfaces";
import React from "react";

type ReportI = {
  date: FirebaseDate;
  id: string;
};

interface Props {
  item: ReportI & Object;
  index: number;
  resolve: (id: string, index: number) => void;
}
const ReportItem = ({ item, index, resolve }: Props) => {
  const { date, ...detail } = item;
  const currentDate = date.toDate();

  const alertItemDetails = () => alert(JSON.stringify(detail));

  const resolveItem = () => {
    const { id } = detail;
    resolve(id, index);
  };

  return (
    <div style={styles.container}>
      <div style={styles.dateText} onClick={alertItemDetails}>
        {currentDate.toUTCString()}
      </div>
      <div style={styles.resolveText} onClick={resolveItem}>
        Mark as resolved
      </div>
    </div>
  );
};

const styles = StyleProps.create({
  container: {
    cursor: "pointer",
    marginInline: 30,
    marginBlock: 15,
    color: SECONDARY_COLOR,
    display: "flex",
    alignItems: "center",
    fontSize: 20,
  },
  dateText: {
    display: "flex",
    flex: 1,
  },
  resolveText: {
    color: LIGHT_RED,
    marginRight: 30,
  },
});

export default ReportItem;
