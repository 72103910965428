import Report from "@containers/Report";
import { StyleProps } from "@utils/interfaces";
import { AppleAuth, EmailAuth, FacebookAuth, GoogleAuth } from "@utils/reports";
import {
  SQLConnection,
  ServerConnection,
  MailConnection,
} from "@utils/services";
import React from "react";

const MainScreen = () => {
  return (
    <div style={styles.container}>
      <Report title="Server Status" entity={ServerConnection} />
      <Report title="BBDD Status" entity={SQLConnection} />
      <Report title="Published Table Cleared" entity={SQLConnection} />
      <Report title="Mail Delivery Status" entity={MailConnection} />
      <Report title="Email Authentication" entity={EmailAuth} />
      <Report title="Google Authentication" entity={GoogleAuth} />
      <Report title="Facebook Authentication" entity={FacebookAuth} />
      <Report title="Apple Authentication" entity={AppleAuth} />
    </div>
  );
};

const styles = StyleProps.create({
  container: {
    paddingBottom: 50,
  },
});

export default MainScreen;
