import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  doc,
  updateDoc,
  orderBy,
} from 'firebase/firestore'
import { ReportCollections } from './reports'

export type FirebaseDate = Timestamp

const firebaseConfig = {
  apiKey: 'AIzaSyDLVM2DUkIhckEKP74tv-s882Lqtb3dHpI',
  authDomain: 'cirkulastatus.firebaseapp.com',
  projectId: 'cirkulastatus',
  storageBucket: 'cirkulastatus.appspot.com',
  messagingSenderId: '1051850360612',
  appId: '1:1051850360612:web:594d071260a21166fed5b1',
  measurementId: 'G-BC5TKPX5E5',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

class FirebaseUtils {
  static getCollection = async (nameCollection: ReportCollections) => {
    const col = query(
      collection(db, nameCollection),
      where('resolved', '==', false),
      orderBy('date', 'desc'),
    )
    const colSnapshot = await getDocs(col)
    const colList = colSnapshot.docs.map((doc) => {
      const id = doc.id
      return { ...doc.data(), id }
    })
    return colList
  }
  static resolveItem = async (
    nameCollection: ReportCollections,
    id: string,
  ) => {
    const col = doc(db, nameCollection, id)
    await updateDoc(col, {
      resolved: true,
    })
  }
}

export { FirebaseUtils }
