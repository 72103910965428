/* eslint-disable react-hooks/exhaustive-deps */
import { ICONS } from "@assets/icons";
import ReportItem from "@containers/ReportItem";
import { SECONDARY_COLOR } from "@utils/colors";
import { StyleProps } from "@utils/interfaces";
import { ReportEntity } from "@utils/reports";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";

interface ReportProps {
  title: string;
  entity: ReportEntity;
}
const Report = ({ title, entity }: ReportProps) => {
  const [list, setList] = useState<any[]>([]);
  const [status, setStatus] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const init = useCallback(async () => {
    var result = await entity.get();
    switch (typeof result) {
      case "boolean":
        setStatus(result);
        break;
      default:
        setList(result);
    }
    setLoading(false);
  }, []);

  const hasError = useMemo(() => list.length > 0 || !status, [list, status]);

  useEffect(() => {
    if (!loading) {
      if (!hasError && open) {
        setOpen(false);
      }
    }
  }, [hasError]);

  const toggleOpen = () => {
    if (hasError) setOpen(!open);
  };

  useEffect(() => {
    init();
  }, [init]);

  const resolveItem = (id: string, index: number) => {
    var newList = [...list];
    newList.splice(index, 1);
    setList(newList);
    if (entity.resolve) {
      entity.resolve(id);
    }
  };
  const renderList = (item: any, index: number) => (
    <ReportItem item={item} index={index} resolve={resolveItem} />
  );
  const renderStatusIcon = () => {
    if (loading)
      return (
        <div style={styles.statusIcon}>
          <ClipLoader color={SECONDARY_COLOR} loading={loading} size={35} />
        </div>
      );
    return (
      <img
        alt="status.icon"
        src={hasError ? ICONS.FAIL : ICONS.PASS}
        style={styles.statusIcon}
      />
    );
  };
  return (
    <div className={open ? "card-displayed" : "card"} style={styles.container}>
      <div onClick={toggleOpen} style={styles.title}>
        {renderStatusIcon()}
        {title}
      </div>
      {hasError && (
        <>
          <div style={styles.subtitle}>Incidencias</div>
          <div>{list.map(renderList)}</div>
        </>
      )}
    </div>
  );
};

const styles = StyleProps.create({
  container: {
    boxShadow: "0px 0px 20px #00000030",
    marginInline: 30,
    marginTop: 20,
    borderRadius: 20,
  },
  title: {
    height: 80,
    fontSize: 20,
    paddingInline: 30,
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    cursor: "pointer",
  },
  subtitle: {
    fontSize: 22,
    marginInline: 30,
    marginBlock: 15,
  },
  statusIcon: {
    width: 35,
    marginRight: 15,
  },
});
export default Report;
