import { FirebaseUtils } from './firebase'

export type ReportCollections =
  | 'email-auth'
  | 'google-auth'
  | 'facebook-auth'
  | 'apple-auth'

export type ReportEntity = {
  get: () => Promise<any>
  resolve?: (id: string) => void
}
export class ReportFirebaseFactory implements ReportEntity {
  collection: ReportCollections

  constructor(col: ReportCollections) {
    this.collection = col
  }

  get = async () => {
    return await FirebaseUtils.getCollection(this.collection)
  }

  resolve = async (id: string) => {
    await FirebaseUtils.resolveItem(this.collection, id)
  }
}

const EmailAuth = new ReportFirebaseFactory('email-auth')
const GoogleAuth = new ReportFirebaseFactory('google-auth')
const FacebookAuth = new ReportFirebaseFactory('facebook-auth')
const AppleAuth = new ReportFirebaseFactory('apple-auth')

export { EmailAuth, GoogleAuth, FacebookAuth, AppleAuth }
