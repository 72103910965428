import React from "react";
import { PRIMARY_COLOR } from "@utils/colors";
import { StyleProps } from "@utils/interfaces";
import { ICONS } from "@assets/icons";

const Header = () => {
  return (
    <div style={styles.container}>
      <img alt="cirkula-logo" src={ICONS.LOGO} style={styles.logo} />
    </div>
  );
};

const styles = StyleProps.create({
  container: {
    backgroundColor: PRIMARY_COLOR,
    paddingInline: 30,
    paddingBlock: 15,
    boxShadow: "0px 5px 15px #00000020",
    position: "relative",
    zIndex: 10,
  },
  logo: {
    width: 60,
  },
});

export default Header;
